























































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalWizardAdjustGoalsViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-wizard-adjust-goals-view-model';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';

@Component({
  name: 'AddCustomInvestorGoalWizardAdjustGoals',
  components: {
    AddCustomInvestorGoalWizardProcess: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalWizardProcess.vue'),
  },
})
export default class AddCustomInvestorGoalWizardAdjustGoals extends Vue {
  @PropSync('customGoalSelected', { type: Object, required: true })
  custom_investor_goal_dto!: CustomInvestorGoalDto;

  add_custom_investor_goal_adjust_goals = Vue.observable(
    new AddCustomInvestorGoalWizardAdjustGoalsViewModel(this),
  );

  created() {
    this.add_custom_investor_goal_adjust_goals.initialize(this.custom_investor_goal_dto);
  }
}
